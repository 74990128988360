import React from "react";
import BlogCard from "../../components/blog/card";
import FeaturedCard from "../../components/blog/featureCard";
// import Pagination from "../../components/common/pagination/Pagination";
import Meta from "../../components/common/meta";
import Container from "../../layout/container";
import { useStaticQuery, graphql } from "gatsby";

const News = (props) => {
  const { allStrapiNew } = useStaticQuery(graphql`
    query NewsAllQuery {
      allStrapiNew(limit: 5, sort: { fields: releaseDate, order: DESC }) {
        edges {
          node {
            id
            slug
            title
            releaseDate
            description
          }
        }
      }
    }
  `);
  const { edges } = allStrapiNew;
  return (
    <>
      <Meta />
      <Container>
        <div className="p-4 px-8 md:py-4">
          <div className="blog">
            <div className="blog__heading">
              <h1 className="my-12 font-bold text-dsc-theme-muted-500 dsc-h1">
                Rundeck News
              </h1>
            </div>
            {/* <FeaturedCard /> */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mb-28">
              {edges.map((item) => (
                <BlogCard data={item?.node} key={item?.node?.id} />
              ))}
            </div>
            {/* <div className="blog__footer">
              <Pagination prefixURL={`/blog/`} pageContext={pageContext} />
            </div> */}
          </div>
        </div>
      </Container>
    </>
  );
};

export default News;
