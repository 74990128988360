import React from "react";
import moment from "moment/moment";
import "moment/locale/ja";

const BlogCard = ({ data }) => {
  return (
    <div className="border border-[#E5E9ED] rounded-[10px] shadow-sm px-4 py-6">
      <a href={`/new/${data?.slug}`}>
        {/* <div className="flex flex-wrap gap-2 mb-6">
          <p
            className=" text-xs
         px-[10px] bg-dsc-theme-accent/10 rounded-full py-[4px] font-normal text-dsc-theme-accent inline-block"
          >
            SRM
          </p>
        </div> */}
        {/* <div className="mb-6 overflow-hidden rounded-md min-h-[130px]">
          <img src="/home/banner.png" alt={"nammer"} />
        </div> */}
        <h2 className="font-semibold text-[#233442] mb-6 dsc-h4 line-clamp-2">
          {data.title}
        </h2>
        <div className="mb-6 font-[300] leading-6 line-clamp-3 dsc-text">
          {data.description}
        </div>
        <p className="text-gray-500 ">
          {moment(data?.releaseDate).format("ll")}
        </p>
      </a>
    </div>
  );
};

export default BlogCard;
